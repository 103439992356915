import dayjs from 'dayjs';
import React from 'react';

import { endPoints } from './Routes/routes';
import { useQuery } from './useQuery';

export const useCurrentDate = () => {
  const currentDateQuery = useQuery<{ date: string }>({
    url: endPoints.date,
  });

  return React.useMemo(
    () => (currentDateQuery.data?.date ? dayjs.utc(currentDateQuery.data.date) : dayjs.utc()),
    [currentDateQuery],
  );
};
