import React from 'react';

const DatacentersFilterContext = React.createContext<{
  selectedDatacenters: {
    ids: string[] | null;
    setIds: (value: string[] | null) => void;
  };
}>({
  selectedDatacenters: {
    ids: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIds: () => {},
  },
});

export const useDatacentersFilter = () => React.useContext(DatacentersFilterContext);

interface IProps {
  children: React.ReactNode;
}

export const DatacentersFilterProvider: React.FC<IProps> = ({ children }) => {
  const [ids, setIds] = React.useState<string[] | null>(null);

  return (
    <DatacentersFilterContext.Provider
      value={{
        selectedDatacenters: { ids, setIds },
      }}
    >
      {children}
    </DatacentersFilterContext.Provider>
  );
};
