import { Align, Col, Container, Row, Text, Wrapper, useThemeContext } from '@datacamp/cdn-ui';
import type { ILinkButtonProps } from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Head } from '@components/Layout/Head/Head';
import { showLiveChat } from '@components/LiveChat/LiveChat';

import styles from './ErrorLayout.module.css';

interface IProps {
  code: number;
  title: string;
  description: string;
  button: Pick<ILinkButtonProps, 'href' | 'onClick'> & { text: string };
}

const cx = classNames.bind(styles);

export const ErrorLayout: React.FC<IProps> = ({ code, title, description, button }) => {
  const appTheme = useThemeContext();

  return (
    <>
      <Head title={title} />
      <Container className={styles.container} width="xl">
        <Align height="max" horizontal="center" vertical="center">
          <Wrapper maxWidth={790}>
            <Row verticalAlign="center">
              <Col md="auto">
                <span className={styles.code}>{code}</span>
              </Col>
              <div className={cx('separator', `app-${appTheme}`)} />
              <Col md="auto">
                <Wrapper maxWidth={420}>
                  <Text className="mb-20" size={32} tag="h1" weight="bold">
                    {title}
                  </Text>
                  <Text className="mb-48" color={{ light: 'grey-700', dark: 'grey-400' }} tag="p">
                    {description}
                  </Text>
                  <Row gap="xs">
                    <Col sm="auto">
                      <Button
                        color="primary"
                        href={button.href}
                        size="sm"
                        theme="button"
                        onClick={button.onClick}
                      >
                        {button.text}
                      </Button>
                    </Col>
                    <Col sm="auto">
                      <Button
                        color="secondary"
                        size="sm"
                        theme="button"
                        onClick={() => showLiveChat()}
                      >
                        Contact us
                      </Button>
                    </Col>
                  </Row>
                </Wrapper>
              </Col>
            </Row>
          </Wrapper>
        </Align>
      </Container>
    </>
  );
};
