import Script from 'next/script';
import React from 'react';

export const GoogleAnalytics: React.FC = () => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CODE) {
    return null;
  }

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CODE}`}
      />
      <Script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CODE}');`,
        }}
      />
    </>
  );
};
