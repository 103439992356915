import type { ILinkButtonProps } from '@datacamp/cdn-ui';
import { Button as BaseButton } from '@datacamp/cdn-ui';
import { useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import React from 'react';

type Props = ILinkButtonProps & {
  disableReactLink?: boolean;
};

export const Button: React.FC<Props> = ({ disableReactLink, ...props }) => {
  const queryClient = useQueryClient();

  const linkTag =
    props.href && props.external !== true && !disableReactLink ? (
      <Link href={props.href} />
    ) : undefined;

  return (
    <BaseButton
      {...props}
      linkTag={linkTag}
      onClick={e => {
        props.onClick?.(e);
        if (props.href && !props.onClick) {
          void queryClient.cancelQueries();
        }
      }}
    />
  );
};
