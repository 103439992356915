import { Align } from '@datacamp/cdn-ui';
import React from 'react';

import { ErrorBoundary } from './ErrorBoundary';
import { Error500Page } from '@components/Pages/Error/Error500Page';

interface IProps {
  layout: 'page' | 'content';
  children?: React.ReactNode;
}

export const LayoutErrorBoundary: React.FC<IProps> = ({ layout, children }) => (
  <ErrorBoundary
    errorComponent={
      layout === 'content' ? (
        <Error500Page title="Application error" />
      ) : (
        <Align height="full" horizontal="center" vertical="center">
          <Error500Page title="Application error" />
        </Align>
      )
    }
  >
    {children}
  </ErrorBoundary>
);
