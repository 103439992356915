const colors = {
  /* Mono */
  colorWhite: '0 0% 100%',
  colorBlack: '0 0% 0%',
  colorShadowDarker: '0 0% 10%',

  /* Greyscale */
  colorGrey50: '228 15% 98%',
  colorGrey100: '228 15% 96%',
  colorGrey150: '228 15% 94%',
  colorGrey200: '228 15% 92%',
  colorGrey300: '228 10% 86%',
  colorGrey400: '228 5% 78%',
  colorGrey500: '228 5% 69%',
  colorGrey600: '228 3% 60%',
  colorGrey700: '228 4% 46%',
  colorGrey800: '228 10% 28%',
  colorGrey850: '228 14% 16%',
  colorGrey900: '228 16% 10%',
  colorGrey950: '228 16% 5%',
  colorGrey1000: '228 16% 3%',

  /* Info */
  colorInfo50: '44 90% 98%',
  colorInfo100: '44 92% 93%',
  colorInfo200: '44 90% 85%',
  colorInfo300: '44 82% 74%',
  colorInfo400: '44 78% 62%',
  colorInfo500: '44 78% 55%',
  colorInfo600: '44 78% 45%',
  colorInfo700: '44 84% 35%',
  colorInfo800: '44 90% 20%',
  colorInfo900: '44 90% 10%',

  /* Blue */
  colorBrandBlue50: '223 64% 98%',
  colorBrandBlue100: '227 69% 95%',
  colorBrandBlue200: '228 71% 85%',
  colorBrandBlue300: '228 70% 76%',
  colorBrandBlue400: '228 62% 63%',
  colorBrandBlue500: '228 60% 52%',
  colorBrandBlue600: '228 64% 38%',
  colorBrandBlue700: '228 66% 28%',
  colorBrandBlue800: '228 66% 20%',
  colorBrandBlue900: '228 66% 14%',
  colorBrandBlue950: '228 51% 8%',

  /* Success */
  colorSuccess50: '145 60% 98%',
  colorSuccess100: '145 60% 95%',
  colorSuccess200: '145 60% 85%',
  colorSuccess300: '145 60% 74%',
  colorSuccess400: '145 60% 62%',
  colorSuccess500: '145 60% 50%',
  colorSuccess600: '145 60% 40%',
  colorSuccess700: '145 60% 30%',
  colorSuccess800: '145 61% 20%',
  colorSuccess900: '145 65% 10%',

  /* Warning */
  colorWarning50: '20 70% 98%',
  colorWarning100: '20 70% 95%',
  colorWarning200: '20 70% 85%',
  colorWarning300: '20 70% 74%',
  colorWarning400: '20 70% 62%',
  colorWarning500: '20 70% 50%',
  colorWarning600: '20 70% 40%',
  colorWarning700: '20 80% 30%',
  colorWarning800: '20 70% 20%',
  colorWarning900: '20 70% 10%',

  /* Error */
  colorError50: '352 70% 98%',
  colorError100: '352 70% 95%',
  colorError200: '352 70% 85%',
  colorError300: '352 70% 74%',
  colorError400: '352 70% 62%',
  colorError500: '352 70% 50%',
  colorError600: '352 70% 40%',
  colorError700: '352 70% 30%',
  colorError800: '352 70% 20%',
  colorError900: '352 70% 10%',

  /* Cyan */
  colorCyan50: '180 54% 98%',
  colorCyan100: '180 54% 95%',
  colorCyan200: '183 55% 85%',
  colorCyan300: '182 54% 76%',
  colorCyan400: '182 55% 63%',
  colorCyan500: '182 55% 55%',
  colorCyan600: '182 55% 40%',
  colorCyan700: '182 56% 30%',
  colorCyan800: '182 55% 20%',
  colorCyan900: '182 60% 10%',

  /* Purple */
  colorPurple50: '264 60% 98%',
  colorPurple100: '264 60% 95%',
  colorPurple200: '264 60% 85%',
  colorPurple300: '264 60% 74%',
  colorPurple400: '264 60% 63%',
  colorPurple500: '264 60% 55%',
  colorPurple600: '264 60% 40%',
  colorPurple700: '264 60% 30%',
  colorPurple800: '264 60% 20%',
  colorPurple900: '264 65% 14%',

  /* Magenta */
  colorMagenta50: '330 46% 98%',
  colorMagenta100: '330 60% 95%',
  colorMagenta200: '330 45% 85%',
  colorMagenta300: '330 45% 74%',
  colorMagenta400: '330 45% 62%',
  colorMagenta500: '330 55% 55%',
  colorMagenta600: '330 45% 40%',
  colorMagenta700: '330 45% 30%',
  colorMagenta800: '330 45% 20%',
  colorMagenta900: '330 50% 12%',

  /* Fuchsia */
  colorFuchsia50: '295 46% 98%',
  colorFuchsia100: '295 45% 95%',
  colorFuchsia200: '294 45% 85%',
  colorFuchsia300: '295 45% 74%',
  colorFuchsia400: '295 45% 62%',
  colorFuchsia500: '295 45% 50%',
  colorFuchsia600: '295 45% 40%',
  colorFuchsia700: '295 45% 30%',
  colorFuchsia800: '295 45% 20%',
  colorFuchsia900: '295 45% 10%',

  /* Lime */
  colorLime50: '75 46% 98%',
  colorLime100: '75 45% 95%',
  colorLime200: '75 45% 85%',
  colorLime300: '75 45% 74%',
  colorLime400: '75 45% 62%',
  colorLime500: '75 45% 50%',
  colorLime600: '75 45% 40%',
  colorLime700: '75 45% 30%',
  colorLime800: '75 45% 20%',
  colorLime900: '75 52% 10%',

  /* Brown */
  colorBrown400: '15 25% 63%',
};

const breakpoints = {
  xs: 0,
  sm: '570px',
  md: '720px',
  lg: '960px',
  xl: '1280px',
  xxl: '1440px',
  full: '1700px',
};

const gridSpacings = {
  'gap-none': '0',
  'gap-xxs': '2px',
  'gap-xs': '10px',
  'gap-sm': '20px',
  'gap-md': '30px',
  'gap-lg': '40px',
  'gap-xl': '60px',
  'gap-xxl': '80px',
};

const containers = { containerLg: '1390px' };

const fontWeights = {
  fontLight: 300,
  fontRegular: 400,
  fontMedium: 500, // used by source code pro
  fontSemibold: 600, // used by proxima nova
  fontBold: 700,
};

const layers = {
  'z-1': 10,
  'z-2': 20,
  'z-3': 30,
  'z-top': 100,
  'z-modal': 1000,
  'z-tooltip': 10001,
  'z-alert': 10002,
  'z-dropdown': 10002,
  'z-livechat': 999,
};

const durations = {
  durationDefault: '300ms',
  durationSkeleton: '1200ms',
};

const boxShadows = {
  lightShadow: `0 0 10px hsl(${colors.colorGrey800} / 10%)`,
  extraLightShadow: `0 1px 3px 0 hsl(${colors.colorGrey600} / 10%)`,
  mediumShadow: `0 3px 6px 0 hsl(${colors.colorGrey600} / 15%)`,
  focusShadow: `0 2px 4px hsl(${colors.colorShadowDarker} / 10%)`,
  largeShadow: `0 0 20px hsl(${colors.colorShadowDarker} / 10%)`,
  tableHoverShadow: `0 1px 5px 2px hsl(${colors.colorBlack} / 8%)`,
  subtleShadow: `0 0 2px 0 hsl(${colors.colorBlack} / 8%)`,
};

const fonts = {
  fontFamily: '"proxima-nova", Arial, "Helvetica Neue", Helvetica, sans-serif',
  fontFamilyMono: '"Source Code Pro", monospace',
};

const shadows = {
  shadow2: `0 0 2px hsl(${colors.colorBlack} / 8%)`,
  shadow3: `0 1px 3px hsl(${colors.colorBlack} / 8%)`,
  shadow4: `0 0 4px hsl(${colors.colorBlack} / 6%)`,
};

const other = {
  navHeight: '74px',
};

module.exports = {
  ...colors,
  ...breakpoints,
  ...durations,
  ...containers,
  ...gridSpacings,
  ...fontWeights,
  ...layers,
  ...boxShadows,
  ...fonts,
  ...shadows,
  ...other,
};
