import { useModal } from '@datacamp/cdn-ui';
import { useFormikContext } from 'formik';
import { Router, useRouter } from 'next/router';
import React from 'react';

import { UnsavedChangesModal } from './UnsavedChangesModal/UnsavedChangesModal';
import { useRefreshCheck } from './useRefreshCheck';

interface IProps {
  dirty?: boolean;
}

let confirmed = false;
const ROUTE_CHANGE_ABORTED_TEXT = 'Route change aborted - unsaved changes';

export const UnsavedChanges: React.FC<IProps> = ({ dirty }) => {
  const router = useRouter();
  const form = useFormikContext();
  const [newUrl, setNewUrl] = React.useState<string | null>(null);
  const { isOpen, closeModal, openModal } = useModal();

  const isDirty = dirty ?? form.dirty;

  useRefreshCheck(isDirty);

  React.useEffect(() => {
    const routeChangeStart = (url: string) => {
      if (!confirmed) {
        setNewUrl(url);
        openModal();
        Router.events.emit('routeChangeError');
        // eslint-disable-next-line no-throw-literal
        throw ROUTE_CHANGE_ABORTED_TEXT; // throwing error is only way to stop next from changing route
      } else {
        confirmed = false;
      }
    };

    if (isDirty) {
      Router.events.on('routeChangeStart', routeChangeStart);
    }
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [isDirty]);

  const handleStay = () => {
    setNewUrl(null);
    closeModal();
  };

  const handleLeave = () => {
    if (newUrl) {
      confirmed = true;
      void router.push(newUrl);
    }
    closeModal();
  };

  return <UnsavedChangesModal isOpen={isOpen} onLeave={handleLeave} onStay={handleStay} />;
};
