import { useQuery as useReactQuery } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-toastify';

import { endPoints } from './Routes/routes';
import { useLogout } from './useLogout';
import type { IClapProxy, IMethod } from './useMutation';
import { getCsrf, getMainUrl } from './useMutation';
import { useUser } from './useUser';
import { parseApiResponse } from '@lib/parseApiResponseData';

const getUseClickHouse = (url: string) => {
  const supportedUrls = [endPoints.clap.stats, endPoints.clap.sum];
  const isSupported = supportedUrls.some(supportedUrl => url.includes(supportedUrl));
  if (isSupported) {
    if (window.location.search.includes('useClickHouse=1')) {
      return true;
    }
    if (window.location.search.includes('useClickHouse=0')) {
      return false;
    }
  }

  return undefined;
};

interface IUseQueryParams {
  url: null | string;
  body?: string | Record<string, unknown> | null;
  method?: IMethod;
  contentType?: 'application/x-www-form-urlencoded' | 'application/json';
  defaultErrorMessage?: string;
  showErrorMessage?: boolean;
  enabled?: boolean;
  clapProxy?: IClapProxy;
}

export const useQuery = <TData>({
  url,
  body,
  method = 'GET',
  contentType = body ? 'application/x-www-form-urlencoded' : 'application/json',
  defaultErrorMessage,
  showErrorMessage = true,
  enabled = true,
  clapProxy,
}: IUseQueryParams) => {
  const { user } = useUser();
  const { onLogout } = useLogout();

  const cacheKey = [url, user.account_info.customer_id];

  const enabledInternal = enabled && (clapProxy === 'general' ? true : user.isLoggedIn);

  const { data, error, isLoading, isPending, isRefetching, isSuccess, refetch } = useReactQuery<
    TData,
    any
  >({
    enabled: enabledInternal && url !== null,
    refetchOnWindowFocus: false,
    queryKey: body ? [...cacheKey, body] : cacheKey,
    queryFn: ({ signal }) => {
      const clapProxyUrl = getMainUrl(clapProxy);

      const getBody = () => {
        if (url && clapProxyUrl) {
          return JSON.stringify({
            method,
            path: url,
            params: body ?? null,
            use_click_house: getUseClickHouse(url),
          });
        }

        if (typeof body === 'string') {
          return body;
        }

        if (body) {
          return JSON.stringify(body);
        }

        return undefined;
      };

      const fetchBody = getBody();

      const promise = fetch(url ? clapProxyUrl ?? url ?? '' : '', {
        method: clapProxyUrl ? 'POST' : method,
        cache: 'no-cache',
        redirect: 'follow',
        body: fetchBody,
        headers: {
          Accept: 'application/json',
          'Content-Type': clapProxyUrl ? 'application/json' : contentType,
          'X-Requested-With': 'XMLHttpRequest',
          csrf: getCsrf(),
        },
        signal,
      })
        .then(async response => {
          if (response.redirected) {
            window.location.href = response.url;
          }
          if (response.status === 401) {
            if (user.isLoggedIn) {
              onLogout();
            }
          }

          if (response.status >= 500) {
            throw new Error('Server error');
          }

          const data = await parseApiResponse<{
            status?: 'error' | number;
            title?: string;
            description?: string;
            error?: string;
            errors?: string[];
          }>(response);

          return { status: response.status, data };
        })
        .then(({ status, data }) => {
          const resStatus = data.status ?? status;

          if (resStatus === 'error') {
            if (data.error) {
              throw new Error(data.error);
            }

            if (data.description) {
              throw new Error(data.description);
            }

            throw new Error('Unknown error');
          } else if (resStatus && resStatus >= 400) {
            if (data.title) {
              throw new Error(data.title);
            } else if (data.errors && Array.isArray(data.errors)) {
              throw new Error(data.errors[0]);
            } else if (data.error) {
              throw new Error(data.error);
            } else {
              throw new Error('Unknown server error');
            }
          }

          return data as TData;
        });

      return promise;
    },
  });

  React.useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    const isCancelledError = error?.hasOwnProperty('silent');
    if (error && !isCancelledError && showErrorMessage) {
      const message = defaultErrorMessage ?? error.message;
      toast(message, { toastId: message, type: 'error' });
    }
  }, [error]);

  return {
    data,
    isLoading: url === null || isLoading || isPending,
    isRefetching,
    isSuccess,
    refetch,
  };
};
