import React from 'react';

export const cdnResourcesSelectTabs = ['CDN Resources', 'Origins'] as const;
type IResourceTab = (typeof cdnResourcesSelectTabs)[number];

type Tab = { name: IResourceTab; setName: (value: IResourceTab) => void };

const initialTab = { name: cdnResourcesSelectTabs[0], setName: () => {} };

const CdnResourcesFilterContext = React.createContext<{
  selectedCdnResources: {
    ids: string[] | null;
    setIds: (value: string[] | null) => void;
  };
  selectedTab: { saved: Tab; unsaved: Tab };
}>({
  selectedCdnResources: {
    ids: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIds: () => {},
  },
  selectedTab: {
    saved: initialTab,
    unsaved: initialTab,
  },
});

export const useCdnResourcesFilter = () => React.useContext(CdnResourcesFilterContext);

interface IProps {
  children: React.ReactNode;
}

export const CdnResourcesFilterProvider: React.FC<IProps> = ({ children }) => {
  const [ids, setIds] = React.useState<string[] | null>(null);
  const [savedTab, setSavedTab] = React.useState<IResourceTab>(cdnResourcesSelectTabs[0]);
  const [unsavedTab, setUnsavedTab] = React.useState<IResourceTab>(cdnResourcesSelectTabs[0]);

  return (
    <CdnResourcesFilterContext.Provider
      value={{
        selectedCdnResources: { ids, setIds },
        selectedTab: {
          saved: { name: savedTab, setName: setSavedTab },
          unsaved: { name: unsavedTab, setName: setUnsavedTab },
        },
      }}
    >
      {children}
    </CdnResourcesFilterContext.Provider>
  );
};
