import { Col, Row, Modal, Text, Wrapper } from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import React from 'react';

import { Button } from '@components/Button/Button';

import styles from './UnsavedChangesModal.module.css';

const cx = classNames.bind(styles);

interface IProps {
  isOpen: boolean;
  onStay: () => void;
  onLeave: () => void;
}

export const UnsavedChangesModal: React.FC<IProps> = ({ isOpen, onStay, onLeave }) => (
  <Modal className={cx('modal', { isOpen })} closeModal={onStay} isOpen={isOpen} width={410}>
    <Wrapper padding={32}>
      <header className="mb-28">
        <Text className="mb-20" size={20} tag="strong" weight="bold">
          Leave this page?
        </Text>
        <Text color={{ light: 'grey-800', dark: 'grey-200' }} size={15} tag="p">
          You have unsaved changes. If you don't want to lose your changes, please go back and save
          them first.
        </Text>
      </header>
      <Row gap="xs" horizontalAlign="end">
        <Col sm="auto">
          <Button color="secondary" size="sm" theme="button" onClick={onStay}>
            Stay on page
          </Button>
        </Col>
        <Col sm="auto">
          <Button color="primary" size="sm" theme="button" onClick={onLeave}>
            Leave without saving
          </Button>
        </Col>
      </Row>
    </Wrapper>
  </Modal>
);
