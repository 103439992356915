import { ThemeContext } from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

import styles from './Toast.module.css';
import CheckIcon from '@icons/line/check-circle.svg';
import CrossIcon from '@icons/solid/cross.svg';
import WarningIcon from '@icons/solid/warning.svg';

const cx = classNames.bind(styles);

export const Toast: React.FC = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <div className={cx('wrapper', theme)}>
      <ToastContainer
        className={styles.content}
        closeButton={({ closeToast }) => (
          <button className={styles.closeButton} onClick={closeToast}>
            <CrossIcon />
          </button>
        )}
        icon={({ type }: { type: string }) =>
          type === 'success' ? <CheckIcon /> : <WarningIcon />
        }
        newestOnTop={false}
        transition={Slide}
        pauseOnFocusLoss
        pauseOnHover
      />
    </div>
  );
};
