import { useWorker, WORKER_STATUS } from '@marceljager/use-worker';
import { captureMessage } from '@sentry/nextjs';
import React from 'react';

export const useStateWorker = <T extends (...fnArgs: Parameters<T>) => ReturnType<T>, R>(
  initialState: R,
  fn: T,
  ...parameters: Parameters<T>
): { state: ReturnType<T> | R; status: typeof status } => {
  const [status, setStatus] = React.useState<'idle' | 'running' | 'success'>('idle');

  const [setWorker, worker] = useWorker(fn);
  const [state, setState] = React.useState<ReturnType<T> | R>(initialState);
  const [queued, setQueued] = React.useState<{ ts: number; params: Parameters<T> | null }>({
    ts: 0,
    params: null,
  });

  React.useEffect(() => {
    setQueued({ ts: new Date().valueOf(), params: parameters });
  }, [...parameters]);

  React.useEffect(() => {
    if (worker.status === WORKER_STATUS.ERROR) {
      captureMessage('Worker error', { level: 'error' });
    }

    if (status === 'running' || worker.status === WORKER_STATUS.RUNNING || queued.ts === 0) {
      return;
    }

    if (queued.params === null) return;
    void setWorker(...(queued.params as Parameters<T>)).then(res => {
      if (res) {
        setState(res);
        setStatus('idle');
      }
    });

    setStatus('running');
    setQueued({ ts: 0, params: null });
  }, [queued.ts, status]);

  React.useEffect(() => {
    if (state !== initialState && queued.ts === 0) {
      setStatus('success');
    }
  }, [state, queued.ts]);

  return { state, status };
};
