import NextLink from 'next/link';
import React from 'react';

interface ILinkProps extends React.AnchorHTMLAttributes<Record<string, any>> {
  href: string;
  name?: string;
  external?: boolean;
  download?: string | boolean;
  title?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children: string | JSX.Element | (string | JSX.Element)[];
  role?: string;
}

export const Link: React.FC<ILinkProps> = ({
  href,
  external = false,
  download,
  title,
  className,
  onClick,
  role,
  children,
  ...props
}) => {
  const linkProps = {
    ...props,
    target: external || href.startsWith('mailto:') ? '_blank' : undefined,
    rel: external ? 'noopener noreferer' : undefined,
    title: title ?? (typeof children === 'string' ? children : undefined),
    download,
    className,
    role,
    onClick,
  };

  if (external) {
    return (
      <a href={href} {...linkProps}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href === '' ? '/' : href} {...linkProps}>
      {children}
    </NextLink>
  );
};
