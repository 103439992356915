import dayjs from 'dayjs';

import type { IBillingMonthlyPlanQuery } from '../BillingOverviewTab/MonthlyPlan/BillingMonthlyPlanMain';
import { endPoints } from '@hooks/Routes/routes';
import { useCurrentDate } from '@hooks/useCurrentDate';
import { useQuery } from '@hooks/useQuery';
import { useUser } from '@hooks/useUser';

export const useMonthlyPlanPaidPeriods = () => {
  const { user } = useUser();
  const date = useCurrentDate();

  const monthlyPlanQuery = useQuery<IBillingMonthlyPlanQuery>({
    url: endPoints.clap.monthlyPlan,
    clapProxy: 'public',
    enabled:
      user.trial === undefined &&
      user.billing?.current_pricing_type === 'monthly_plan' &&
      !user.billing.external_billing,
  });

  const paidUntil = monthlyPlanQuery.data
    ? dayjs(monthlyPlanQuery.data.paid_until * 1000)
    : undefined;

  const lastDayCurrentMonth = date.endOf('month');
  const lastDayNextMonth = date.add(1, 'month').endOf('month');

  const currentPeriodIsPaid = paidUntil
    ? paidUntil.isAfter(lastDayCurrentMonth, 'day') || paidUntil.isSame(lastDayCurrentMonth, 'day')
    : false;
  const bothPeriodsArePaid = paidUntil
    ? paidUntil.isAfter(lastDayNextMonth, 'day') || paidUntil.isSame(lastDayNextMonth, 'day')
    : false;

  const daysDiff = Math.abs(date.diff(lastDayCurrentMonth, 'day'));

  return {
    isLoading: monthlyPlanQuery.isLoading,
    autoRechargeEnabled: monthlyPlanQuery.data?.has_auto_recharge_enabled,
    currentPeriodIsPaid,
    bothPeriodsArePaid,
    paidUntil,
    daysDiff,
  };
};
