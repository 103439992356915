import React from 'react';

import { UnauthenticatedLayout } from '@components/Layout/UnauthenticatedLayout/UnauthenticatedLayout';
import { Login } from '@components/Pages/Login/Login';

const Page: React.FC = () => (
  <UnauthenticatedLayout>
    <Login />
  </UnauthenticatedLayout>
);

export default Page;
