import React from 'react';

import { useStateWorker } from './useStateWorker';
import { useQuery } from '@hooks/useQuery';
import { endPoints } from '@routes';

export interface IOriginCdn {
  id: number;
  label: string;
}

export type IOriginGeneralValues = {
  id: string;
  label: string;
  note?: string;
  base_dir?: string;
  cdns: IOriginCdn[];
};

export type IUrlOrigin = IOriginGeneralValues & {
  type: 'url';
  host: string;
  scheme: string;
  port?: number | null;
};

export type IAwsOrigin = IOriginGeneralValues & {
  type: 'aws';
  host: string;
  scheme: string;
  aws_region: string;
  aws_access_key_id: string;
};

export type IStorageOrigin = IOriginGeneralValues & {
  type: 'storage';
  server: {
    hostname: string;
    location: string;
  };
  username: string;
  usage: { space: number; nodes: number; time: string };
};

export type IObjectStorageOrigin = IOriginGeneralValues & {
  type: 'object-storage';
  created_at: string;
  host: string;
  scheme: string;
  port?: number;
  cluster_id: string;
  bucket_name: string;
  timeout: number;
  access_key_id?: string;
  access_secret?: string;
  object_storage_type?: 'origin' | 'real_time_log';
  usage: { file_count: number; size_bytes: number };
};

export type IOriginType = IOrigin['type'];

export type IOrigin = IUrlOrigin | IAwsOrigin | IStorageOrigin | IObjectStorageOrigin;

interface IOriginState {
  aws: IAwsOrigin[];
  url: IUrlOrigin[];
  storage: IStorageOrigin[];
  'object-storage': IObjectStorageOrigin[];
}

const getOriginWithLabel = (label: string, origins: IOriginState) => {
  const allOrigins = [
    ...origins.aws,
    ...origins.storage,
    ...origins.url,
    ...origins['object-storage'],
  ];

  return allOrigins.find(origin => origin.label === label);
};

export const getUniqueOriginLabel = (label: string, origins: IOriginState) => {
  const existingCopyOrigin = origin ? getOriginWithLabel(`${label} copy`, origins) : undefined;

  return `${label} copy${existingCopyOrigin ? ` ${Math.random().toString(36).substring(8)}` : ''}`;
};

const getGroupedOrigins = (origins?: IOrigin[]) => {
  if (!origins) return {};

  return origins.reduce<IOriginState>(
    (groupedOrigins, origin) => {
      if (!groupedOrigins[origin.type]) {
        groupedOrigins[origin.type] = [];
      }

      groupedOrigins[origin.type].push(origin as any);

      return groupedOrigins;
    },
    { url: [], aws: [], storage: [], 'object-storage': [] },
  );
};

export const useOrigins = (enabled = false) => {
  const originsQuery = useQuery<IOrigin[]>({
    url: endPoints.clap.origin,
    clapProxy: 'public',
    enabled,
  });

  const groupedOrigins = useStateWorker(undefined, getGroupedOrigins, originsQuery.data);

  const getSelectedOrigin = React.useCallback(
    (originId: string): IOrigin | undefined => {
      const origin = originsQuery.data?.find(origin => origin.id === originId);
      if (!origin) return undefined;

      return origin;
    },
    [originsQuery.data],
  );

  const isLoading = !groupedOrigins.state || Object.keys(groupedOrigins.state).length === 0;

  return {
    origins: isLoading ? undefined : (groupedOrigins.state as IOriginState),
    isLoading,
    getSelectedOrigin,
  };
};
