import { ThemeContext } from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import React from 'react';
import AuthCodeInput from 'react-auth-code-input';

import styles from './AuthCode.module.css';

interface IProps {
  variant?: 'primary' | 'secondary';
  onChange: (code: string) => void;
}

const cx = classNames.bind(styles);

export const AuthCode: React.FC<IProps> = ({ variant = 'primary', onChange }) => {
  const theme = React.useContext(ThemeContext);

  return (
    <AuthCodeInput
      allowedCharacters="numeric"
      inputClassName={cx('input', theme, variant)}
      onChange={onChange}
    />
  );
};
