import { useField, useFormikContext } from 'formik';
import React from 'react';

import { AuthCode } from './AuthCode';

interface IProps {
  name: string;
  variant?: React.ComponentProps<typeof AuthCode>['variant'];
  submitOnFill?: boolean;
}

export const FormAuthCode: React.FC<IProps> = ({ name, variant, submitOnFill }) => {
  const { values, submitForm } = useFormikContext<Record<string, string>>();

  const field = useField({
    name,
    validate: value => (/^\d{6}$/.exec(value) === null ? 'Invalid code' : undefined),
  });

  React.useEffect(() => {
    if (submitOnFill && values[name].length === 6) {
      void submitForm();
    }
  }, [values[name], submitOnFill]);

  return (
    <AuthCode
      variant={variant}
      onChange={value => {
        void field[2].setValue(value);
      }}
    />
  );
};
