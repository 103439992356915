import { useRouter } from 'next/router';
import React from 'react';

import { getAllRoutesWithAccess } from './getAllRoutesWithAccess';
import { useMonthlyPlanPaidPeriods } from '@components/Pages/Billing/MonthlyPlanPage/useMonthlyPlanPaidPeriods';
import { useDevToolsContext } from '@hooks/useDevToolsContext';
import { useOrigins } from '@hooks/useOrigins';
import { initialUserData, useUser } from '@hooks/useUser';

const RoutesContext = React.createContext(getAllRoutesWithAccess({ user: initialUserData }));

export const useRoutes = () => React.useContext(RoutesContext);

interface Props {
  accessConfig: Record<string, boolean>;
  children: React.ReactNode;
}

export const RoutesProvider: React.FC<Props> = ({ accessConfig, children }) => {
  const router = useRouter();
  const { user } = useUser();

  const twoFactor = user.account_info.required_team_members_2fa
    ? user.configuration.two_factor_authentication_enabled
    : true;

  const withDevTools = useDevToolsContext();
  const { origins } = useOrigins(
    user.isLoggedIn &&
      !router.pathname.startsWith('/support') &&
      user.account_info.email_confirmed &&
      twoFactor,
  );

  const { isLoading } = useMonthlyPlanPaidPeriods();

  const config = React.useMemo(
    () =>
      getAllRoutesWithAccess({
        user,
        enableStagingPages: withDevTools,
        withStorage: origins ? origins.storage.length > 0 : false,
        accessConfig,
      }),
    [user, isLoading, origins, router.pathname, accessConfig],
  );

  return <RoutesContext.Provider value={config}>{children}</RoutesContext.Provider>;
};
