import React from 'react';

export const useRefreshCheck = (dirty: boolean) => {
  const dirtyRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    dirtyRef.current = dirty;
  }, [dirty]);

  React.useEffect(() => {
    const handleConfirm = (e: BeforeUnloadEvent) => {
      if (dirtyRef.current) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleConfirm);

    return () => {
      window.removeEventListener('beforeunload', handleConfirm);
    };
  }, []);
};
