import React from 'react';

interface IProps {
  timeout?: number;
}

const scrollToWithNav = (y: number) => {
  const nav = document.getElementById('header');
  const navHeight = nav ? nav.offsetHeight : 0;

  window.scroll({
    top: y - navHeight,
    left: 0,
    behavior: 'smooth',
  });
};

export const ScrollHere: React.FC<IProps> = ({ timeout = 0 }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (scrollRef?.current) {
        scrollToWithNav(scrollRef.current.getBoundingClientRect().top + window.pageYOffset - 50);
      }
    }, timeout);
  }, [scrollRef]);

  return <div ref={scrollRef} />;
};
