import type { MutationStatus } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import type { FormikErrors, FormikState, FormikProps, FormikHelpers, FormikConfig } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';

import { ScrollHere } from './ScrollHere';
import { UnsavedChanges } from './UnsavedChanges/UnsavedChanges';
import type { IClapProxy, IMethod } from '@hooks/useMutation';
import { useMutation } from '@hooks/useMutation';

type IValue =
  | Record<string, unknown>
  | Record<string, number | string | string[] | Record<string, unknown> | undefined>
  | unknown[];

type IFormWithEndpoint<TResult, TFormValues> = {
  endpointUrl: string;
  onSuccess?: (data: TResult, variables?: TFormValues) => void;
  onError?: (error: { message: string; type?: string }, variables?: TFormValues) => void;
};

type IFormWithoutEndpoint = {
  endpointUrl?: undefined;
  onSuccess?: undefined;
  onError?: undefined;
};

type IProps<TResult, TFormValues> = (
  | IFormWithEndpoint<TResult, TFormValues>
  | IFormWithoutEndpoint
) & {
  method?: IMethod;
  initialValues: TFormValues;
  resetFormOnSuccess?: boolean;
  successMessage?: string | JSX.Element | ((values?: TFormValues) => string | JSX.Element);
  excludedErrorTypes?: string[];
  showSuccessMessage?: boolean;
  thankYouComponent?: JSX.Element;
  onSubmit?: (variables: TFormValues) => void;
  getValues?: (values: TFormValues) => IValue;
  enableReinitialize?: boolean;
  clapProxy?: IClapProxy;
  validate?: FormikConfig<TFormValues>['validate'];
  validateAfterValueChange?: string;
  watchForChanges?: boolean;
  children: (props: {
    values: TFormValues;
    setFieldValue: FormikHelpers<TFormValues>['setFieldValue'];
    errors: FormikErrors<TFormValues>;
    data: TResult | null;
    isSuccess: boolean;
    isLoading: boolean;
    status: MutationStatus;
    isValid: boolean;
    dirty: boolean;
    resetForm: FormikHelpers<TFormValues>['resetForm'];
    submitForm: (() => Promise<void>) & (() => Promise<any>);
    validateForm: (
      values?: Partial<FormikState<TFormValues>>,
    ) => Promise<FormikErrors<TFormValues>>;
    submitButtonProps: { type: 'submit'; loading: boolean; disabled: boolean };
  }) => React.ReactNode;
};

export const FormWrapper = <TResult extends Record<string, unknown>, TFormValues extends IValue>({
  endpointUrl,
  method,
  getValues,
  initialValues,
  resetFormOnSuccess = false,
  successMessage,
  showSuccessMessage = true,
  excludedErrorTypes,
  thankYouComponent,
  enableReinitialize = true,
  clapProxy,
  validateAfterValueChange,
  validate,
  watchForChanges = true,
  onSubmit,
  onSuccess,
  onError,
  children,
}: IProps<TResult, TFormValues>) => {
  const formikRef = React.useRef<FormikProps<TFormValues> | null>(null);

  React.useEffect(() => {
    if (validateAfterValueChange && formikRef?.current) {
      void formikRef?.current.validateForm();
    }
  }, [validateAfterValueChange]);

  const [data, setData] = React.useState<TResult | null>(null);
  const mutation = useMutation<TResult, TFormValues>({
    method,
    clapProxy,
    excludedErrorTypes,
    onSuccess: (result, variables) => {
      if (showSuccessMessage && !thankYouComponent) {
        const defaultMessage =
          result?.description && typeof result.description === 'string'
            ? result.description
            : 'Request was successful.';

        const message =
          typeof successMessage === 'function' ? successMessage?.(variables) : successMessage;
        toast(message ?? defaultMessage, { type: 'success' });
      }

      onSuccess?.(result, variables);
      setData(result);
    },
    onError,
  });

  if (mutation.isSuccess && thankYouComponent) {
    return (
      <>
        <ScrollHere />
        {thankYouComponent}
      </>
    );
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      innerRef={formikRef}
      validate={validate}
      onSubmit={(values, { resetForm, setStatus }) => {
        if (endpointUrl) {
          mutation.mutate(
            {
              url: endpointUrl,
              variables: getValues ? getValues(values) : values,
              formValues: values,
            },
            {
              onSuccess: () => {
                setStatus('success');
                if (resetFormOnSuccess) {
                  resetForm();
                }
              },
            },
          );
        }
        onSubmit?.(values);
      }}
    >
      {({
        values,
        errors,
        dirty,
        isValid,
        setFieldValue,
        resetForm,
        validateForm,
        submitForm,
        status,
      }) => (
        <Form noValidate>
          {children({
            values,
            setFieldValue,
            errors,
            data,
            isSuccess: mutation.isSuccess,
            isLoading: mutation.isPending,
            status: mutation.status,
            resetForm,
            submitForm,
            isValid,
            dirty,
            validateForm,
            submitButtonProps: {
              type: 'submit',
              loading: mutation.isPending,
              disabled: !dirty || mutation.isPending || !isValid,
            },
          })}
          {watchForChanges && mutation.status === 'idle' && endpointUrl && (
            <UnsavedChanges dirty={status === 'success' ? false : dirty} />
          )}
        </Form>
      )}
    </Formik>
  );
};
