/* eslint-disable react/no-unknown-property */
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { useUser } from '@hooks/useUser';

interface IHeadProps {
  title: string;
  titlePostfix?: string | null;
  description?: string;
  canonical?: string;
  ogUrl?: string;
  imageOg?: string;
  noIndex?: boolean;
  children?: React.ReactNode;
}

export const Head: React.FC<IHeadProps> = ({
  description,
  title,
  titlePostfix,
  noIndex,
  canonical,
  ogUrl,
  imageOg,
  children,
}) => {
  const router = useRouter();
  const { user } = useUser();

  const postfix = React.useMemo(() => {
    if (titlePostfix === null) return '';
    if (titlePostfix) return titlePostfix;

    if (
      user.account_info.customer_slug === 'cra-main' ||
      user.account_info.customer_slug === 'cra-sub'
    )
      return ' | ČRA CDN';
    if (router.asPath.startsWith('/support')) return ' | CDN77 documentation';
    return ' | CDN77.com';
  }, [titlePostfix, router]);

  return (
    <NextHead>
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <title>{`${title}${postfix}`}</title>
      <meta
        content={
          ogUrl
            ? `${ogUrl ?? canonical ?? ''}`
            : `${router && router.pathname !== '/' ? router.asPath : ''}`
        }
        property="og:url"
      />
      {description && (
        <>
          <meta content={description.replace(/\s\s+/g, ' ')} name="description" />
          <meta
            content={description.replace(/\s\s+/g, ' ')}
            prefix="og: http://ogp.me/ns#"
            property="og:description"
          />
          <meta content={description.replace(/\s\s+/g, ' ')} name="twitter:description" />
        </>
      )}
      {noIndex ? (
        <meta content="noindex, follow" name="robots" />
      ) : (
        router &&
        router.pathname !== '_error' && (
          <link
            href={`https://client.cdn77.com${canonical ?? `${router && router.pathname !== '/' ? router.pathname : ''}`}`}
            rel="canonical"
          />
        )
      )}
      <meta content={`${title}${postfix}`} prefix="og: http://ogp.me/ns#" property="og:title" />
      <meta content={`${title}${postfix}`} name="twitter:title" />
      <meta content="summary" name="twitter:card" />
      <meta
        content={imageOg ?? '/cdn-og-support.png'}
        prefix="og: http://ogp.me/ns#"
        property="og:image"
      />
      {imageOg && <meta content="1200" prefix="og: http://ogp.me/ns#" property="og:image:width" />}
      {imageOg && <meta content="628" prefix="og: http://ogp.me/ns#" property="og:image:height" />}
      <meta content={imageOg ?? '/cdn-og-support.png'} name="twitter:image" />
      {children}
    </NextHead>
  );
};
