import {
  Col,
  Row,
  Badge,
  Icon,
  InlineCode,
  Modal,
  ModalLayout,
  ModalButtons,
  useModal,
  useThemeContext,
  PaddingFullWidth,
  Table,
  TableActions,
  Text,
  Input,
  useTable,
} from '@datacamp/cdn-ui';
import classNames from 'classnames/bind';
import React from 'react';
import { toast } from 'react-toastify';

import { ThemeSwitcher } from './ThemeSwitcher/ThemeSwitcher';
import { Button } from '@components/Button/Button';
import { useUser } from '@hooks/useUser';

import styles from './StagingBar.module.css';
import SettingsIcon from '@icons/line/settings.svg';
import ArrowIcon from '@icons/solid/arrow.svg';
import CheckIcon from '@icons/solid/check.svg';
import DeleteIcon from '@icons/solid/delete.svg';

interface IAccount {
  accountName: string;
  accountEmail: string;
  id: string;
  oldId?: number;
  isMainAccount?: boolean;
  note: string;
}

const cx = classNames.bind(styles);

export const StagingBar: React.FC = () => {
  const appTheme = useThemeContext();
  const modal = useModal();
  const [inputValue, setInputValue] = React.useState('');
  const [users, setUsers] = React.useState<IAccount[]>([]);
  const { user } = useUser();

  const loginAs = (id: string) => {
    void fetch('/api/public/l', {
      method: 'POST',
      body: JSON.stringify({ id }),
    }).then(() => {
      window.location.reload();
    });
  };

  const loadHistory = () => {
    const usersLocalStorage = window.localStorage.getItem('users');

    if (usersLocalStorage) {
      setUsers(JSON.parse(usersLocalStorage) as IAccount[]);
    }
  };

  const saveUser = () => {
    const newUsersArray = [
      ...users.filter(acc => acc.id !== user.id),
      {
        oldId: user.account_info.customer_id,
        id: user.id,
        accountName: user.account_info.full_name,
        accountEmail: user.account_info.email_address,
        isMainAccount: user.account_info.is_main_account,
        note: inputValue,
      },
    ];

    window.localStorage.setItem('users', JSON.stringify(newUsersArray));

    setUsers(newUsersArray);
    setInputValue('');
  };

  const removeUser = (acc: IAccount) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Remove ${acc.accountName} - ${acc.accountEmail} (${acc.id})?`)) {
      const newUsersArray = users.filter(current => current.id !== acc.id);

      window.localStorage.setItem('users', JSON.stringify(newUsersArray));
      setUsers(newUsersArray);
    }
  };

  React.useEffect(() => {
    loadHistory();
  }, []);

  const tableData = React.useMemo(
    () =>
      users?.map(
        savedUser =>
          ({
            current: savedUser.id === user.id,
            ...savedUser,
            actions: savedUser.id,
          }) ?? [],
      ),
    [users],
  );

  const table = useTable(tableData, column => [
    column.accessor('current', {
      header: '',
      size: 5,
      minSize: 5,
      cell: cell => {
        const value = cell.getValue();

        if (value) {
          return (
            <Icon color="success-700" size={12}>
              <CheckIcon />
            </Icon>
          );
        }
        return null;
      },
    }),
    column.accessor('oldId', {
      header: 'Old ID',
      size: 5,
      minSize: 5,
      cell: cell => {
        const value = cell.getValue();

        if (value) {
          return <InlineCode>{value}</InlineCode>;
        }
        return null;
      },
    }),
    column.accessor('accountName', {
      header: 'Name',
      size: 20,
      cell: cell => (
        <Text size={14} tag="strong" weight="semibold">
          {cell.getValue()}
        </Text>
      ),
    }),
    column.accessor('accountEmail', { header: 'Email', size: 30 }),
    column.accessor('isMainAccount', {
      header: 'Type',
      size: 10,
      minSize: 10,
      cell: cell => {
        const value = cell.getValue();

        if (value) {
          return (
            <Badge size="sm" theme="grey">
              main
            </Badge>
          );
        }
        return (
          <Badge size="sm" theme="grey">
            sub
          </Badge>
        );
      },
    }),
    column.accessor('note', { header: 'Note', size: 40 }),
    column.display({
      id: 'actions',
      size: 5,
      cell: cell => (
        <TableActions
          actions={[
            {
              icon: <DeleteIcon />,
              label: 'Remove',
              onClick: () => removeUser(cell.row.original),
            },
            {
              icon: <ArrowIcon />,
              label: 'Login',
              onClick: () => {
                loginAs(cell.row.original.id);
              },
            },
          ]}
        />
      ),
    }),
  ]);

  if (!user.isLoggedIn) {
    return null;
  }

  return (
    <div className={cx('wrapper', `app-${appTheme}`)}>
      <Text className="mr-8" size={12} tag="span">
        {user.account_info.full_name} - <b>{user.account_info.email_address}</b>
        {!user.account_info.is_main_account ? '(subaccount)' : null}
      </Text>
      <Button
        color="secondary"
        size="xs"
        theme="button"
        width={{ desktop: 'auto', mobile: 'auto' }}
        onClick={modal.openModal}
      >
        <Icon color="currentColor" size={14}>
          <SettingsIcon />
        </Icon>
      </Button>
      <Modal closeModal={modal.closeModal} isOpen={modal.isOpen} width={900}>
        <ModalLayout
          buttons={<ModalButtons closeButtonText="Close" closeModal={modal.closeModal} />}
          title="App settings"
        >
          <div className="mb-40">
            <Row horizontalAlign="between">
              <Col md="auto">
                <Text tag="p">Theme</Text>
              </Col>
              <Col md="auto">
                <ThemeSwitcher />
              </Col>
            </Row>
          </div>
          <PaddingFullWidth className="mb-10">
            <Table
              {...table}
              config={{
                accountName: { visibleFrom: 'xs' },
                accountEmail: { visibleFrom: 'xs' },
                note: { visibleFrom: 'xs' },
              }}
              initialSort={[{ id: 'accountEmail', desc: false }]}
            />
          </PaddingFullWidth>
          <div className="mb-8">
            <Row gap="xs" horizontalAlign="end">
              <Col xs={16}>
                <Input
                  name="note"
                  placeholder="Note"
                  size="xs"
                  value={inputValue}
                  onChange={({ target }) => setInputValue(target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button color="primary" size="xs" theme="button" onClick={saveUser}>
                  Save current user
                </Button>
              </Col>
            </Row>
          </div>
          <Row gap="xs" horizontalAlign="end">
            <Col xs="auto">
              <Button
                color="secondary"
                size="xs"
                theme="button"
                type="button"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async () => {
                  const usersString = await window.navigator.clipboard.readText();

                  try {
                    const usersArray = JSON.parse(usersString) as IAccount[];
                    if (
                      Array.isArray(usersArray) &&
                      usersArray.length > 0 &&
                      typeof usersArray[0].id === 'string'
                    ) {
                      window.localStorage.setItem('users', usersString);
                      setUsers(usersArray);
                      toast.success('Imported from clipboard');
                    } else {
                      toast.error('Invalid config in clipboard');
                    }
                  } catch (error) {
                    toast.success(`Error: ${JSON.stringify(error)}`);
                  }
                }}
              >
                Import
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                size="xs"
                theme="button"
                type="button"
                onClick={() => {
                  void window.navigator.clipboard.writeText(JSON.stringify(users));
                  toast.success('Exported to clipboard');
                }}
              >
                Export
              </Button>
            </Col>
          </Row>
        </ModalLayout>
      </Modal>
    </div>
  );
};
