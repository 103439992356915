import React from 'react';

import { DatePickerProvider } from '@hooks/Reports/DatePickerContainer';
import { CdnResourcesFilterProvider } from '@hooks/Reports/useCdnResourcesFilterContext';
import { DatacentersFilterProvider } from '@hooks/Reports/useDatacentersFilterContext';
import { TimerangeProvider } from '@hooks/Reports/useTimerangeContext';

interface IProps {
  children: React.ReactNode;
}

export const AuthenticatedProviders: React.FC<IProps> = ({ children }) => (
  <DatePickerProvider>
    <TimerangeProvider>
      <CdnResourcesFilterProvider>
        <DatacentersFilterProvider>{children}</DatacentersFilterProvider>
      </CdnResourcesFilterProvider>
    </TimerangeProvider>
  </DatePickerProvider>
);
