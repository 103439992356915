import React from 'react';

const DatePickerContext = React.createContext<
  [boolean, (isOpen: boolean) => void]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>([false, () => {}]);

export const useDatePickerContext = () => React.useContext(DatePickerContext);

interface Props {
  children: React.ReactNode;
}

export const DatePickerProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <DatePickerContext.Provider value={[isOpen, setIsOpen]}>{children}</DatePickerContext.Provider>
  );
};
