import React from 'react';

import { ErrorLayout } from './ErrorLayout/ErrorLayout';

interface IProps {
  title: string;
}

export const Error500Page: React.FC<IProps> = ({ title }) => (
  <ErrorLayout
    button={{
      text: 'Reload page',
      onClick: () => {
        window.location.reload();
      },
    }}
    code={500}
    description="Sorry, something went wrong. Try reloading the page. If the issue persists, feel free to contact our tech support."
    title={title}
  />
);
