import { endPoints } from './Routes/routes';
import { useMutation } from '@hooks/useMutation';

export const useLogout = () => {
  const logoutMutation = useMutation({ method: 'POST' });

  const onLogout = () => logoutMutation.mutate({ url: endPoints.logout });

  return { onLogout };
};
