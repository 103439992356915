import React from 'react';

const getValueFromLocalStorage = (name: string) => {
  if (localStorage.getItem(name)) {
    try {
      const item = localStorage.getItem(name);
      if (item) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(item);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export const useLocalStorageState = <T>(
  localStorageName: string,
  initialValue: T,
): [T, (value: T) => void, (value: T) => void] => {
  const [state, setState] = React.useState<T>(initialValue);

  React.useEffect(() => {
    const savedValues = getValueFromLocalStorage(localStorageName);
    if (savedValues !== null) {
      setState(getValueFromLocalStorage(localStorageName) as T);
    }
  }, []);

  const handleSetState = (value: T) => {
    localStorage.setItem(localStorageName, JSON.stringify(value));
    setState(value);
  };

  return [state, handleSetState, setState];
};
