import { Switcher } from '@datacamp/cdn-ui';
import React from 'react';

import type { AppTheme } from './AppThemeContext';
import { useAppTheme } from './AppThemeContext';

export const ThemeSwitcher: React.FC = () => {
  const [appTheme, setAppTheme] = useAppTheme();

  return (
    <Switcher<AppTheme>
      items={[
        { id: 'light', content: 'Light' },
        { id: 'dark', content: 'Dark' },
        { id: 'system', content: `System` },
      ]}
      selectedItem={appTheme}
      onChangeSelectedItem={setAppTheme}
    />
  );
};
